import React from "react"
import { graphql } from "gatsby"
// import Container from "../components/container"
// import GraphQLErrorList from "../components/graphql-error-list"
import ProjectPost from "../components/templates/projectpost"
import Seo from "../components/seo"
import EmailUs from "../components/EmailUs"
// import SEO from "../components/seo"

export const query = graphql`
  query ProjectPostTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      publishedAt
      title
      slug {
        current
      }
      image {
        asset {
          url
          _id
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`

const BlogPostTemplate = props => {
  const { data, errors } = props
  const project = data && data.project
  return (
    <>
      {project && (
        <Seo title={project.title || "Untitled"} image={project.mainImage} />
      )}
      {project && <ProjectPost {...project} />}
      <EmailUs />
    </>
  )
}
export default BlogPostTemplate
