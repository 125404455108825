import React from "react"
import { Figure } from "../../MainImage"
import PortableText from "../../portableText"

function ProjectPost(props) {
  const { _rawBody, title, image } = props
  return (
    <section>
      <article className="bg-[#F9FAFB] pb-20">
        {image && image.asset && (
          <>
            <Figure
              node={image}
              alt={title}
              className="w-full h-full aspect-[800/233]"
            />
            <h1
              className={`  text-[42px] text-[#0D456F] mt-8 mb-5 w-10/12 mx-auto`}
            >
              {title}
            </h1>
          </>
        )}
        <div className=" w-10/12 mx-auto">
          <div className="mb-32 text-[#1D263A]">
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
        </div>
      </article>
    </section>
  )
}

export default ProjectPost
